.sticky {
  position: fixed;
  z-index: 50;
  top: 0;
  width: 100%;
  box-shadow: 5px 0px 15px 2px rgba(192, 192, 192, 0.3);
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;

}

.navbar {
  box-shadow: 5px 0px 15px 2px rgba(192, 192, 192, 0.3);
}

.navbar-dark {
  border-bottom: 1px solid #374151;
  box-shadow: none;
}

.open {
  overflow-y: scroll;
  height: 100vh;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
